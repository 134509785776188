.feature {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 280px;
  margin: 0 0 50px;
  display: flex;
}

.feature-child {
  text-align: center;
  background-color: #333;
  border-radius: 10px;
  width: 33%;
  height: 280px;
  margin: 10px;
  padding: 10px;
  position: relative;
}

.feature-child a {
  color: #fff;
  border-radius: 10px;
  height: 175px;
  padding: 10px;
  text-decoration: none;
  display: block;
  overflow: hidden;
  box-shadow: 0 0 4px #000;
}

.feature img {
  object-fit: cover;
  border-radius: 10px;
  height: 100%;
  max-height: 175px;
  overflow: hidden;
}

.feature-h1 {
  margin: 20px 10px 40px;
}

.feature a .title {
  opacity: 1;
  transition: opacity .5s;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%);
}

.feature a:hover .title {
  opacity: 0;
}

.feature .description {
  margin: 20px 0 5px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 100;
}

@media (max-width: 900px) {
  .feature {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin: 0 0 50px;
    display: flex;
  }

  .feature-child {
    text-align: center;
    background-color: #333;
    border-radius: 10px;
    width: 90%;
    margin: 0 auto 10px;
    padding: 10px;
    position: relative;
  }

  .feature a .title {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%);
  }
}

/*# sourceMappingURL=index.70130d23.css.map */
