.feature {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 280px;
  margin: 0px 0px 50px;
}

.feature-child {
  position: relative;
  text-align: center;
  width: 33%;
  height: 280px;
  background-color: #333;
  margin: 10px;
  border-radius: 10px;
  padding: 10px;
}

.feature-child a {
  color: #fff;
  text-decoration: none;
  display: block;
  height: 175px;
  /* max-height: 175px; */
  overflow: hidden;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 0px 4px #000;
}

.feature img {
  overflow: hidden;
  object-fit: cover;
  border-radius: 10px;

  max-height: 175px;
  height: 100%;
}

.feature-h1 {
  margin: 20px 10px 40px 10px;
}

.feature a .title {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0%);
  opacity: 1;
  transition: opacity 0.5s ease;
}
.feature a:hover .title {
  opacity: 0;
}

.feature .description {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 100;
  margin: 20px 0 5px 0;
  /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
}

@media (max-width: 900px) {
  .feature {
    /* display: block; */
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    margin: 0px 0px 50px;
    display: flex;
    flex-direction: column;
    height: auto;
  }
  .feature-child {
    position: relative;
    text-align: center;
    width: 90%;
    background-color: #333;
    margin: 0 auto 10px auto;
    border-radius: 10px;
    padding: 10px;
  }
  .feature a .title {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 0%);
  }
}
/* 

.feature {
  width: 100%;
}

.feature-child {
  position: relative;
  text-align: center;
  width: 30%;
  height: 280px;
  background-color: #333;
  margin: 10px;
  border-radius: 10px;
  padding: 10px;
  float: left;
}

.feature-child a {
  color: #fff;
  text-decoration: none;
  display: block;
  height: 175px;
  overflow: hidden;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 0px 4px #000;
}

.feature img {
  overflow: hidden;
  object-fit: cover;
  border-radius: 10px;

  max-height: 175px;
  height: 100%;
}

.feature-h1 {
  margin-left: 10px;
}

.feature-p {
  margin: 10px 10px 30px 10px;
}

.feature a .title {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0%);
  opacity: 1;
  transition: opacity 0.5s ease;
}
.feature a:hover .title {
  opacity: 0;
}

.feature .description {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 100;
  margin-top: 25px;
  
}

@media (max-width: 900px) {
  .feature {
    display: block;
    width: 100%;
    margin: 0px 0px 50px;
  }
  .feature-child {
    position: relative;
    text-align: center;
    width: 90%;
    background-color: #333;
    margin: 0 auto 10px auto;
    border-radius: 10px;
    padding: 10px;
  }
  .feature a .title {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 0%);
  }
} */
